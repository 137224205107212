import React, { ReactNode, useState } from 'react';
import FeatureTitle from '../components/Feature/FeatureTitle';
import FeatureWrapper from '../components/Feature/FeatureWrapper';
import Warning from '../components/Feature/Warning';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Logo from '../components/Header/Logo';
import Navbar from '../components/Header/Navbar';
import SigninButton from '../components/Header/SigninButton';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { LinkButton } from '../components/Header/SigninButton';
import { NavLink } from '../components/Header/NavLink';
import { authLogout } from '../store/auth/auth.actions';
import { useHistory } from 'react-router-dom';
import BurgerBtn from '../components/Header/BurgerBtn';
import MobileMenu from '../components/Header/MobileMenu';
import MobileNavLink from '../components/Header/MobileNavLink';
import Banner from '../components/Header/Banner';
/*---> Component <---*/
const HeaderCompound = ({
  children,
  hideFeature,
  hideNav,
  size = 'lg',
}: PropsType) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const token = useSelector((state: RootState) => state.auth.token);

  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);

  const handleClick = () => {
    setOpenMobileMenu(prev => !prev);
  };

  return (
    <>
      <HeaderWrapper wrapClass={size || 'lg'}>
        <Navbar hideNav={hideNav}>
          <Logo />
          {!token ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NavLink onClick={() => history.push('/signin')}>Sign In</NavLink>
              <NavLink onClick={() => history.push('/signup')}>
                Join Here
              </NavLink>
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <NavLink onClick={() => history.push('/users')}>
                  Member Matches
                </NavLink>
                <NavLink onClick={() => history.push('/history')}>
                  Members Chronologically
                </NavLink>
              </div>
              <div style={{ display: 'flex' }}>
                <NavLink onClick={() => history.push('/profile')}>
                  yOUr Profile
                </NavLink>
                <NavLink
                  onClick={() => {
                    dispatch(authLogout());
                    history.replace('/signin');
                  }}
                >
                  Log Out
                </NavLink>
              </div>
            </>
          )}
          <BurgerBtn open={openMobileMenu} onClick={handleClick} />
        </Navbar>

        <MobileMenu open={openMobileMenu}>
          {!token ? (
            <>
              <MobileNavLink onClick={() => history.push('/signin')}>
                Sign In
              </MobileNavLink>
              <MobileNavLink onClick={() => history.push('/signup')}>
                Join Here
              </MobileNavLink>
            </>
          ) : (
            <>
              <MobileNavLink onClick={() => history.push('/users')}>
                Member Matches
              </MobileNavLink>
              <MobileNavLink onClick={() => history.push('/history')}>
                Members Chronologically
              </MobileNavLink>
              <MobileNavLink onClick={() => history.push('/profile')}>
                yOUr Profile
              </MobileNavLink>
              <MobileNavLink
                onClick={() => {
                  dispatch(authLogout());
                  history.replace('/signin');
                }}
              >
                Log Out
              </MobileNavLink>
            </>
          )}
        </MobileMenu>

        {!hideFeature ? (
          <FeatureWrapper>

            <img src="https://alchetron.com/cdn/michael-f-price-85770a4f-c675-427a-9c1a-9145d374475-resize-750.jpg" alt="logo" style={{ marginRight: '3px', filter: 'grayscale(100%)', transform: 'scale(0.73)', borderRadius: '33px' }} />
            <div>
              <FeatureTitle>"Always keep learning and adapting.</FeatureTitle>
              <FeatureTitle>
                The business world is constantly evolving, and successful
                investors are those who stay curious, open-minded, and committed
                to continuous improvement."
              </FeatureTitle>

              <div style={{ marginTop: '23px' }}>
                <FeatureTitle>~ Michael F. Price ~</FeatureTitle>
              </div>
            </div>

          </FeatureWrapper>
        ) : <Banner />}
        {/* {children} */}
      </HeaderWrapper>
    </>
  );
};

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
  hideFeature?: boolean;
  hideNav?: boolean;
  size?: 'lg' | 'md' | 'sm';
}

export default HeaderCompound;
