import React from "react";
import FooterWrapper from "../components/Footer/FooterWrapper";
import FooterTitle from "../components/Footer/FooterTitle";
import FooterRow from "../components/Footer/FooterRow";
import FooterColumn from "../components/Footer/FooterColumn";
import FooterLink from "../components/Footer/FooterLink";

/*---> Component <---*/
const FooterCompound = () => {
  return (
    <FooterWrapper>
     
      <FooterRow>
        Boomer Sooner! Please be prideful in your Price College alumni status. Let's work together to build a better tomorrow and a more sustainable, joyful world like only Sooners know how to do. Sooner magic baby!

        "Don't let yesterday take up too much of today." - Will Rogers, OU alumni

"Success is not final, failure is not fatal: It is the courage to continue that counts." - Barry Switzer

"Hard work beats talent when talent doesn't work hard." - Bob Stoops

"Be yOUr best today, from what you have learned in the past, and what you will become in the future" - OU alumni, unknown
      </FooterRow>
    </FooterWrapper>
  );
}

export default FooterCompound;
