import { Dispatch } from 'redux';
import { sendRequest } from '../../lib/api';
import { IUtilAction } from './util.type';

const setUtils = (utils: IUtilAction): IUtilAction => utils;

export const loadDegrees = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'degrees');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_DEGREES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadGradLevels = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'grad_levels');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_GRAD_LEVELS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadIbcCompanies = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'ibc_companies');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_IBC_COMPANIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadStudentOrgs = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'student_orgs');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_STUDENT_ORGS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadAssociations = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'associations');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ASSOCIATIONS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadIndustries = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'industries');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_INDUSTRIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadHobbies = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'hobbies');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_HOBBIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadJobTitles = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'job_titles');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_JOB_TITLES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadMilitaryBranches = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'military_branches');

    if (res.status) {
      dispatch(
        setUtils({ type: 'UTILS/SET_MILITARY_BRANCHES', payload: res.data }),
      );
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSchools = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'schools');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_SCHOOLS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadCities = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'cities');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_CITIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadCountries = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'countries');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_COUNTRIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSkills = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'skills');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_SKILLS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};
