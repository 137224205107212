import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Navbar from '../components/Header/Navbar';
import Logo from '../components/Header/Logo';
import FooterCompound from '../compounds/FooterCompound';
import SignFormWrapper from '../components/SignForm/SignFormWrapper';
import SignFormBase from '../components/SignForm/SignFormBase';
import SignFormTitle from '../components/SignForm/SignFormTitle';
import SignFormInput from '../components/SignForm/SignFormInput';
import SignFormButton from '../components/SignForm/SignFormButton';
import SignFormText from '../components/SignForm/SignFormText';
import SignFormLink from '../components/SignForm/SignFormLink';
import SignFormCaptcha from '../components/SignForm/SignFormCaptcha';
import SignFormError from '../components/SignForm/SignFormError';
import Warning from '../components/Feature/Warning';
import { authLogin } from '../store/auth/auth.actions';
import { useDispatch } from 'react-redux';

/*---> Component <---*/
const SigninPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const IsInvalid = password === '' || emailAddress === '';

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const res = await dispatch(authLogin({ email: emailAddress, password }));
    if (res === true) {
      history.replace('/users');
    } else {
      setError(res);
    }
  };

  const handleSignupClick = () => {
    history.push('/signup');
    const HeaderElement: HTMLElement | null = document.getElementById('header');
    HeaderElement?.scrollIntoView();
  };

  return (
    <>
      <HeaderWrapper>
        <Navbar hideNav={true}>
          <Logo />
        </Navbar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>
              Boomer Sooner! Please Sign in to be matched with OU Price College
              Alumni:
            </SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormInput
              type='text'
              placeholder='Email Address'
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <SignFormInput
              type='password'
              placeholder='Password'
              autoComplete='off'
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <SignFormButton disabled={IsInvalid} type='submit'>
              Sign In
            </SignFormButton>
            <SignFormText>
              <SignFormLink onClick={handleSignupClick}>
                or Register as a New Member
              </SignFormLink>
            </SignFormText>
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
};

export default SigninPage;
