import React, { useState } from 'react';
import styled from 'styled-components'

const BurgerBtnWrapper = styled.button`
  background-color: rgb(152, 32, 34);
  border: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-between;
  padding: 12px;
  position: relative;
  width: 48px;
  cursor: pointer;
  display: none;

  @media (max-width: 1160px) {
    display: block;
  }

  &:hover {
    background-color: #c3292c;
  }

  & .line-1 {
    transform: translatey(-9px);
  }
  & .line-2 {
    opacity: 1;
  }
  & .line-3 {
    transform: translatey(9px);
  }

  &.open .line-1 {
    transform: rotate(45deg);
  }
  &.open .line-2 {
    opacity: 0;
  }
  &.open .line-3 {
    transform: rotate(-45deg);
  }
`;

const BLine = styled.span`
  background-color: #fff;
  display: block;
  height: 3px;
  position: absolute;
  top: 50%;
  transition-duration: .4s;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(.84,.06,.52,1.8);
  width: 24px;
`;

const SrcOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const BurgerBtn: React.FC<{ open: boolean, onClick: () => void }> = ({
  open,
  onClick
}) => (
    <BurgerBtnWrapper 
      className={open ? 'open' : ''} 
      onClick={onClick}
    >
      <SrcOnly />
      <BLine className='line-1'/>
      <BLine className='line-2'/>
      <BLine className='line-3'/>
    </BurgerBtnWrapper>
);

export default BurgerBtn;
