import { Avatar, Modal } from 'antd';
import { IUserProfile } from '../store/auth/auth.type';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { compareTraits } from '../lib/traits';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import './style.css';

interface Props {
  title?: string;
  visible: boolean;
  user: IUserProfile;
  onClose: () => void;
}

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0px;
  background-image: url('/images/misc/home-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 12px;
  border-radius: 8px;
`;

const AvatarWrapper = styled.div`
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 4px 5px 2px rgb(0 0 0 / 22%);
  margin: 0 12px;
  width: fit-content;
  cursor: pointer;
`;

const TraitWrap = styled.div`
  flex: 1;
  text-align: center;
  font-weight: bold;
  color: #fff;
  & .shared {
    font-size: 40px;
  }
`;

const UserNameWrap = styled.div`
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  & .user-name {
    font-family: 'Allura';
    font-weight: bold;
    font-size: 28px;
  }
`;

const InfoBox = styled.div`
  position: relative;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 12px 12px 7px 12px;
  margin-top: 32px;

  & .title {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 14px;
    color: #8f8f8f;
    font-weight: 600;
    background-color: #fff;
    padding: 0 8px;
  }
`;

const InfoValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size; 18px;
  & span {
    display: block;
    margin-right: 5px;
    padding: 4px 8px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  & .active {
    background-color: #982022;
    color: #fff;
    border-color: #982022;
  }
`;

const AboutMe = styled.div`
  padding: 12px 24px;
  & .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  & .content {
    font-size: 16px;
  }
`;

const UserModal = ({ title, visible, user, onClose }: Props) => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  return (
    <Modal
      title={title}
      onCancel={onClose}
      onOk={onClose}
      open={visible}
      width={700}
    >
      <TopWrap
        style={
          user?.profile_url
            ? { backgroundImage: `url(${user?.profile_url})` }
            : {}
        }
      >
        <TraitWrap>
          <div className='desc'>shared traits is</div>
          <div>
            <span className='shared'>
              {profile ? Math.round(compareTraits(profile, user)) : 0}
            </span>
            <span>%</span>
          </div>
        </TraitWrap>
        <AvatarWrapper>
          <Avatar
            shape='circle'
            size={190}
            src={user.profile_pic}
            icon={<UserOutlined />}
          />
        </AvatarWrapper>
      
      </TopWrap>

      <UserNameWrap>
    <div style={{ color: 'black' }}>
        {user.first_name} {user.last_name}
    </div>
    <div style={{ color: 'black' }}>{user.email}</div>
</UserNameWrap>

      {!!user.headline && (
        <AboutMe>
          <div className='title'>About me</div>
          <div className='content'>{user.headline}</div>
        </AboutMe>
      )}
      {!!user.youtube_video_url && (
        <ReactPlayer width='auto' url={user.youtube_video_url} />
      )}
      <InfoBox>
        <div className='title'>Degrees</div>
        <InfoValues>
          {user.degrees?.map(x => (
            <span
              key={x.id}
              className={
                profile?.degrees?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Grad levels</div>
        <InfoValues>
          {user.grad_levels?.map(x => (
            <span
              key={x.id}
              className={
                profile?.grad_levels?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>JCPenney:</div>
        <InfoValues>{user.jc_penny ? 'Yes' : 'No'}</InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>IBC program</div>
        <InfoValues>
          <span
            className={
              profile?.ibc_company_id === user.ibc_company_id ? 'active' : ''
            }
          >
            {user.ibc_company?.name}
          </span>
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Military Branch</div>
        <InfoValues>
          <span
            className={
              profile?.military_branch?.id === user.military_branch?.id
                ? 'active'
                : ''
            }
          >
            {user.military_branch?.name}
          </span>
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Student Org</div>
        <InfoValues>
          {user.student_orgs?.map(x => (
            <span
              key={x.id}
              className={
                profile?.student_orgs?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Associations</div>
        <InfoValues>
          {user.associations?.map(x => (
            <span
              key={x.id}
              className={
                profile?.associations?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Industries</div>
        <InfoValues>
          {user.industries?.map(x => (
            <span
              key={x.id}
              className={
                profile?.industries?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Hobbies</div>
        <InfoValues>
          {user.hobbies?.map(x => (
            <span
              key={x.id}
              className={
                profile?.hobbies?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Cities</div>
        <InfoValues>
          {user.cities?.map(x => (
            <span
              key={x.id}
              className={
                profile?.cities?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Country</div>
        <InfoValues>
          {user.countries?.map(x => (
            <span
              key={x.id}
              className={
                profile?.countries?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Job Titles</div>
        <InfoValues>
          {user.job_titles?.map(x => (
            <span
              key={x.id}
              className={
                profile?.job_titles?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      <InfoBox>
        <div className='title'>Schools</div>
        <InfoValues>
          {user.schools?.map(x => (
            <span
              key={x.id}
              className={
                profile?.schools?.some(y => y.id === x.id) ? 'active' : ''
              }
            >
              {x.name}
            </span>
          ))}
        </InfoValues>
      </InfoBox>
      {!!user.type && (
        <InfoBox>
          <div className='title'>
            {user.type === 'OWN'
              ? 'I have a startup company and need roles / skills'
              : 'I have these skills for a startup company'}
          </div>
          <InfoValues>
            {user.skills?.map((x, i) => (
              <span
                key={i}
                className={
                  profile?.skills?.some(y => y.id === x.id) ? 'active' : ''
                }
              >
                {x.name}
              </span>
            ))}
          </InfoValues>
        </InfoBox>
      )}
    </Modal>
  );
};

export default UserModal;
