import { useEffect } from 'react';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SigninPage from './pages/SigninPage';
import RegisterPage from './pages/RegisterPage';
import BrowsePage from './pages/BrowsePage';
import NotFoundPage from './pages/NotFoundPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/rootReducer';
import { Dispatch } from 'redux';
import {
  loadAssociations,
  loadCities,
  loadCountries,
  loadDegrees,
  loadGradLevels,
  loadHobbies,
  loadIbcCompanies,
  loadIndustries,
  loadJobTitles,
  loadMilitaryBranches,
  loadSchools,
  loadSkills,
  loadStudentOrgs,
} from './store/util/util.actions';
import ProfilePage from './pages/ProfilePage';
import { authLoadMe } from './store/auth/auth.actions';
import UsersPage from './pages/UsersPage';
import HistoryPage from './pages/HistoryPage';

const appLoadConfigs = async (dispatch: Dispatch<any>) => {
  await dispatch(loadDegrees());
  await dispatch(loadGradLevels());
  await dispatch(loadIbcCompanies());
  await dispatch(loadStudentOrgs());
  await dispatch(loadAssociations());
  await dispatch(loadIndustries());
  await dispatch(loadHobbies());
  await dispatch(loadJobTitles());
  await dispatch(loadMilitaryBranches());
  await dispatch(loadSchools());
  await dispatch(loadCities());
  await dispatch(loadCountries());
  await dispatch(loadSkills());
};

const App = () => {
  const dispatch = useDispatch<any>();

  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    appLoadConfigs(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(authLoadMe());
    }
  }, [dispatch, token]);

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        {!token ? (
          <>
            <Route path='/signin'>
              <SigninPage />
            </Route>
            <Route path='/signup'>
              <RegisterPage />
            </Route>
          </>
        ) : (
          <>
            <Route path='/profile'>
              <ProfilePage />
            </Route>
            <Route path='/users'>
              <UsersPage />
            </Route>
            <Route path='/history'>
              <HistoryPage />
            </Route>
          </>
        )}
        <Route path='/browse'>
          <BrowsePage />
        </Route>
        <Route path='*'>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
