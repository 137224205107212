import {
  IAssociation,
  ICity,
  ICountry,
  IDegree,
  IGradLevel,
  IHobby,
  IIbcCompany,
  IIndustry,
  IJobTitle,
  IMilitaryBranch,
  ISchool,
  ISkill,
  IStudentOrg,
  IUtilAction,
} from './util.type';

interface IUtilState {
  degrees: IDegree[];
  grad_levels: IGradLevel[];
  ibc_companies: IIbcCompany[];
  student_orgs: IStudentOrg[];
  associations: IAssociation[];
  industries: IIndustry[];
  hobbies: IHobby[];
  job_titles: IJobTitle[];
  military_branches: IMilitaryBranch[];
  schools?: ISchool[];
  cities?: ICity[];
  countries?: ICountry[];
  skills?: ISkill[];
}

const initState: IUtilState = {
  degrees: [],
  grad_levels: [],
  ibc_companies: [],
  student_orgs: [],
  associations: [],
  industries: [],
  hobbies: [],
  job_titles: [],
  military_branches: [],
  schools: [],
  cities: [],
  countries: [],
  skills: [],
};

const utilReducer = (state = initState, action: IUtilAction): IUtilState => {
  switch (action.type) {
    case 'UTIL/SET_DEGREES': {
      return { ...state, degrees: action.payload };
    }

    case 'UTIL/SET_GRAD_LEVELS': {
      return { ...state, grad_levels: action.payload };
    }

    case 'UTIL/SET_IBC_COMPANIES': {
      return { ...state, ibc_companies: action.payload };
    }

    case 'UTIL/SET_STUDENT_ORGS': {
      return { ...state, student_orgs: action.payload };
    }

    case 'UTILS/SET_ASSOCIATIONS': {
      return { ...state, associations: action.payload };
    }

    case 'UTILS/SET_INDUSTRIES': {
      return { ...state, industries: action.payload };
    }

    case 'UTILS/SET_HOBBIES': {
      return { ...state, hobbies: action.payload };
    }

    case 'UTILS/SET_JOB_TITLES': {
      return { ...state, job_titles: action.payload };
    }

    case 'UTILS/SET_MILITARY_BRANCHES': {
      return { ...state, military_branches: action.payload };
    }

    case 'UTILS/SET_SCHOOLS': {
      return { ...state, schools: action.payload };
    }

    case 'UTILS/SET_CITIES': {
      return { ...state, cities: action.payload };
    }

    case 'UTILS/SET_COUNTRIES': {
      return { ...state, countries: action.payload };
    }

    case 'UTILS/SET_SKILLS': {
      return { ...state, skills: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default utilReducer;
