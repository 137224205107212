import React from 'react'
import styled from 'styled-components'

/*---> Styles <---*/
export const NavLink = styled.div`
  display: none;
  height: fit-content;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  padding: 12px 12px;
  margin: 8px 8px;
  cursor: pointer;
  background-color: #982022;
  border: 0;
  border-radius: 4px;
  white-space: nowrap;

  @media (min-width: 1161px) {
    display: block;
  }
  
  &:hover {
    background-color: #c3292c;
  }
`

export default NavLink
