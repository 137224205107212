import React from 'react'
import styled from 'styled-components'

/*---> Component <---*/
const Seperator = () => {
  return <Wrapper />
}

/*---> Styles <---*/
export const Wrapper = styled.div`
  border: 3px solid #fff;
  width: 50%;
  margin: 0 auto;
`

export default Seperator
