import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { AvatarSize } from 'antd/es/avatar/SizeContext';

const AvatarWrapper = styled.div`
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 4px 5px 2px rgb(0 0 0 / 22%);
  margin-bottom: 8px;
  width: fit-content;
  cursor: pointer;
`;

const UserAvatar: React.FC<{ src: String; size: AvatarSize, onClick: () => void }> = ({
  src,
  size,
  onClick,
}) => (
    <AvatarWrapper onClick={() => onClick()}>
      <Avatar
        shape='circle'
        size={size || 90}
        src={src}
        icon={<UserOutlined />}
      />
    </AvatarWrapper>
);


export default UserAvatar;
