import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import banner from '../../assets/images/final.png'
/*---> Component <---*/
const Banner = () => {
    const history = useHistory()

    return (
        <BannerWrapper>
            <Image
                src={banner}
                alt='Price Alumni banner'
            />
        </BannerWrapper>
    )
}

/*---> Styles <---*/
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify content: center;
    align-items: center;
`
export const Image = styled.img`
    width: 100%;
    min-height: 260px;

    @media (max-width: 768px) {
        // margin-top: 80px;
    }

    @media (max-width: 550px) {
        // margin-top: 140px;
    }

    @media (max-width: 370px) {
        // margin-top: 180px;
    }
`

export default Banner
