import { useState, useEffect } from 'react';
import FooterCompound from '../compounds/FooterCompound';
import HeaderCompound from '../compounds/HeaderCompound';
import { IUserProfile } from '../store/auth/auth.type';
import { sendRequest } from '../lib/api';
import { Button, Input } from 'antd';
import ReactPlayer from 'react-player';
import UserModal from '../compounds/UserModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { updateProfile } from '../store/auth/auth.actions';
import { compareTraits } from '../lib/traits';
import UserAvatar from '../components/Card/UserAvatar';
import styled from 'styled-components';

const DetailCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  border: 1px solid gray;
  border-radius: 8px;
  position: relative;
  background-image: url(/images/misc/red-circuit-bg.png);
  background-size: cover;
  background-repeat: no-repeat;

  & .profile {
    padding: 20px 32px;

    & > div {
      min-width: 400px;
      width: 400px;
      margin: 0 auto;
    }
  }
  & .video-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 32px;
    & > div {
      width: 480px;
      min-width: 480px;
    }
  }
  & .trait-wrap {
    flex: 1;
    padding: 20px 32px;
    min-width: 360px;
    max-width: 980px;
  }
  & .trait-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #fff;
    padding: 4px 0;
    font-size: 18px;
    margin-bottom: 24px;
  }
  & .avatar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .user-name {
    text-align: center;
    font-weight: 600;
    margin-bottom: 4px;
    font-family: 'Allura';
    font-size: 32px;
  }
  & .user-num {
    position: absolute;
    top: 20px;
    left: 32px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  & .joined-at {
    text-align: center;
    font-size: 0.8rem;
  }
  & .date {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
  & .description {
    margin-top: 24px;
    word-break: keep-all;
  }
  & .divider {
    // margin: 80px 0;
    // border-right: 1px solid gray;
  }

  @media (max-width: 540px) {
    & .profile {
      & > div {
        min-width: auto;
        width: 100%;
      }
    }
    & .video-wrap {
      & > div {
        min-width: auto;
      }
    }
  }

  @media (max-width: 980px) {
    display: block;
  }

  @media (max-width: 1064px) {
    & .profile {
      width: 100%;
    }
  }
`;

const FeatureWrapper = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 32px;
  & .title {
    color: #b1b1b1;
    font-size: 10px;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FeatureItem = styled.div`
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
  margin-right: 6px;
  margin-top: 4px;
  &.active {
    background-color: #fff;
    color: #982022;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  & label {
    color: #d5d5d5;
    margin-right: 1rem;
  }
  & input {
    width: 100px;
    margin-right: 1rem;
  }
`;

const HistoryPage = () => {
  const dispatch = useDispatch<any>();
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [users, setUsers] = useState<IUserProfile[]>();
  const [openUser, setOpenUser] = useState<IUserProfile>();
  const [conCnt, setConCnt] = useState(
    profile?.connections_size?.toString() ?? '',
  );

  const updateConSize = async () => {
    const res = await dispatch(updateProfile({ connections_size: conCnt }));
    if (res === true) {
      alert('Updated successfully');
    }
  };

  useEffect(() => {
    sendRequest({}, 'GET', 'users').then(res => {
      const us = res.data;
      us.sort((a: any, b: any) => (a.created_at > b.created_at ? -1 : 1));
      setUsers([profile, ...us]);
    });
  }, []);

  return (
    <div>
      <HeaderCompound hideFeature={true} size='md' />
      <Pagination>
        <label>
          Type in your number of matching traits or more in order to receive an
          email about a match when new Price Alumni join, yours is set to:
        </label>
        <Input
          type='number'
          value={conCnt}
          onChange={e => setConCnt(e.target.value)}
        />
        <Button onClick={updateConSize}>Update</Button>
      </Pagination>
      <div>
        {users?.map((user, idx) => (
          <DetailCard
            key={idx}
            style={
              user?.profile_url
                ? { backgroundImage: `url(${user?.profile_url})` }
                : {}
            }
          >
            <div className='profile'>
              <div>
                <div className='user-num'>{`#${1 + idx}`}</div>
                <div className='avatar-wrap' onClick={() => setOpenUser(user)}>
                  <UserAvatar
                    src={user.profile_pic || ''}
                    size={140}
                    onClick={() => {}}
                  />
                </div>
                <div className='user-name' onClick={() => setOpenUser(user)}>
                  {`${user.first_name} - ${user.last_name}`}
                </div>
                <div className='joined-at'>
                  {'( '}
                  <span>Member jointed at</span>
                  <span className='date'>{user.created_at}</span>
                  {' )'}
                </div>
                <FeatureWrapper>
                  <FeatureList>
                    {user.job_titles?.map(x => (
                      <FeatureItem
                        key={x.id}
                        className={
                          profile?.job_titles?.some(y => y.id === x.id)
                            ? 'active'
                            : ''
                        }
                      >
                        {x.name}
                      </FeatureItem>
                    ))}
                    {user.degrees?.map(x => (
                      <FeatureItem
                        key={x.id}
                        className={
                          profile?.degrees?.some(y => y.id === x.id)
                            ? 'active'
                            : ''
                        }
                      >
                        {x.name}
                      </FeatureItem>
                    ))}
                  </FeatureList>
                </FeatureWrapper>
                {user.headline && (
                  <div className='description'>
                    {user.headline.slice(0, 200)}
                    {user.headline.length > 200 ? '...' : ''}
                  </div>
                )}
              </div>
            </div>
            {!!user.youtube_video_url && (
              <>
                <div className='divider'></div>
                <div className='video-wrap'>
                  <div>
                    <ReactPlayer
                      width='auto'
                      height={280}
                      url={user.youtube_video_url}
                    />
                  </div>
                </div>
              </>
            )}
            <div className='divider'></div>
            <div className='trait-wrap'>
              <div className='trait-title'>
                <div>Shared traits</div>
                <div>
                  <span style={{ fontSize: 32, fontWeight: 600 }}>
                    {profile ? Math.round(compareTraits(profile, user)) : 0}
                  </span>
                  %
                </div>
              </div>
              <FeatureList>
                {user.degrees?.map(
                  x =>
                    profile?.degrees?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.grad_levels?.map(
                  x =>
                    profile?.grad_levels?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {profile?.ibc_company_id === user.ibc_company_id && (
                  <FeatureItem className='active'>
                    {user.ibc_company?.name}
                  </FeatureItem>
                )}
                {profile?.military_branch?.id === user.military_branch?.id && (
                  <FeatureItem className='active'>
                    {user.military_branch?.name}
                  </FeatureItem>
                )}
                {user.student_orgs?.map(
                  x =>
                    profile?.student_orgs?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.associations?.map(
                  x =>
                    profile?.associations?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.industries?.map(
                  x =>
                    profile?.industries?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.hobbies?.map(
                  x =>
                    profile?.hobbies?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.cities?.map(
                  x =>
                    profile?.cities?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.countries?.map(
                  x =>
                    profile?.countries?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
                {user.schools?.map(
                  x =>
                    profile?.schools?.some(y => y.id === x.id) && (
                      <FeatureItem key={x.id} className='active'>
                        {x.name}
                      </FeatureItem>
                    ),
                )}
              </FeatureList>
            </div>
          </DetailCard>
        ))}
      </div>
      <FooterCompound />
      {openUser && (
        <UserModal
          visible={true}
          user={openUser}
          onClose={() => setOpenUser(undefined)}
        />
      )}
    </div>
  );
};

export default HistoryPage;
