import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Navbar from '../components/Header/Navbar';
import Logo from '../components/Header/Logo';
import TextArea from 'antd/es/input/TextArea';
import SignFormWrapper from '../components/SignForm/SignFormWrapper';
import SignFormBase from '../components/SignForm/SignFormBase';
import SignFormTitle from '../components/SignForm/SignFormTitle';
import SignFormInput from '../components/SignForm/SignFormInput';
import SignFormButton from '../components/SignForm/SignFormButton';
import SignFormText from '../components/SignForm/SignFormText';
import SignFormLink from '../components/SignForm/SignFormLink';
import SignFormError from '../components/SignForm/SignFormError';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { authRegister } from '../store/auth/auth.actions';
import ImgCrop from 'antd-img-crop';
import { Checkbox, Input, Select, Upload } from 'antd';
import CameraIcon from '../components/icons/CameraIcon';
import { Avatar } from 'antd';
import { UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import { ThreeCircles } from 'react-loader-spinner';
import Seperator from '../components/Seperator/Seperator';

interface IFormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  grad_level_id: number[] | null;
  degree_id: number[] | null;
  jc_penny: boolean;
  ibc_company_id: number | null;
  student_org_id: number[] | null;
  association_id: number[] | null;
  industry_id: number[] | null;
  hobby_id: number[] | null;
  city_id: number[] | null;
  country_id: number[] | null;
  job_title_id: number[] | null;
  military_branch_id: number | null;
  school_id: number[] | null;
  profile_pic?: string | null;
  profile_url?: string | null;
  grad_year: string | null;
  headline: string | null;
  youtube_video_url: string | null;
  type: 'OWN' | 'JOIN' | null;
  skills_id: number[] | null;
}

const defaultForm: IFormData = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  grad_level_id: null,
  degree_id: null,
  jc_penny: false,
  ibc_company_id: null,
  student_org_id: null,
  association_id: null,
  industry_id: null,
  hobby_id: null,
  country_id: null,
  city_id: null,
  job_title_id: null,
  military_branch_id: null,
  school_id: null,
  grad_year: null,
  headline: null,
  youtube_video_url: null,
  type: null,
  skills_id: null,
};

const RegisterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const utils = useSelector((state: RootState) => state.utils);

  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    grad_level_id:
      utils.grad_levels.length > 0 ? [utils.grad_levels[0].id] : null,
    degree_id: utils.degrees.length > 0 ? [utils.degrees[0].id] : null,
    ibc_company_id:
      utils.ibc_companies.length > 0 ? utils.ibc_companies[0].id : null,
    student_org_id:
      utils.student_orgs.length > 0 ? [utils.student_orgs[0].id] : null,
    association_id:
      utils.associations.length > 0 ? [utils.associations[0].id] : null,
    industry_id: utils.industries.length > 0 ? [utils.industries[0].id] : null,
    hobby_id: utils.hobbies.length > 0 ? [utils.hobbies[0].id] : null,
    country_id:
      utils.countries && utils.countries.length > 0
        ? [utils.countries[0].id]
        : null,
    city_id:
      utils.cities && utils.cities.length > 0 ? [utils.cities[0].id] : null,
    job_title_id: utils.job_titles.length > 0 ? [utils.job_titles[0].id] : null,
    military_branch_id:
      utils.military_branches.length > 0 ? utils.military_branches[0].id : null,
    school_id:
      utils.schools && utils.schools.length > 0 ? [utils.schools[0].id] : null,
  });
  const [showIbc, setShowIbc] = useState(false);
  const [showMilBrc, setShowMilBrc] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const updateForm = (key: keyof IFormData, value: any) => {
    setFormData(prv => ({ ...prv, [key]: value }));
  };

  const IsInvalid =
    formData.first_name.length <= 0 ||
    formData.last_name.length <= 0 ||
    formData.email.length <= 0 ||
    formData.password.length < 3;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formData.profile_pic) {
      alert(
        'You MUST add a profile headshot photo to be a part of the Price Alumni community. Thanks! You are beautiful!',
      );
      return;
    }

    setLoading(true);
    const res = await dispatch(authRegister(formData));
    setLoading(false);
    if (res === true) {
      history.replace('/history');
      window.scrollTo({ top: 0 });
    } else {
      setError(res);
    }
  };
  const handleLoginClick = () => {
    history.push('/signin');
    const HeaderElement: HTMLElement | null = document.getElementById('header');
    HeaderElement?.scrollIntoView();
  };
  const handleFileChange = (type: 'profile_pic' | 'profile_url', file: any) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      updateForm(type, reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <HeaderWrapper>
        <Navbar hideNav={true}>
          <Logo />
        </Navbar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>Become A Price Alumni Member:</SignFormTitle>
            <div style={{ marginBottom: '20px' }}>
              Price Alumni is created and run by fellow Price alumni Sooners!
              OUr community stands proud and strong, embracing a standard of
              excellence in networking using this platom. We value your privacy
              and assure you that your details will never be shared without your
              express permission. It's absolutely free to join so simply fill
              out the form below, and then you are instantly matched to other
              Sooners like yOU! ONLY Price Alumni are allowed to join!
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>

            {error ? <SignFormError>{error}</SignFormError> : null}

            <SignFormInput
              type='text'
              placeholder='First Name:'
              value={formData.first_name}
              onChange={({ target }) => updateForm('first_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Last Name:'
              value={formData.last_name}
              onChange={({ target }) => updateForm('last_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Email Address:'
              value={formData.email}
              onChange={({ target }) => updateForm('email', target.value)}
            />
            <SignFormInput
              type='password'
              placeholder='Password:'
              autoComplete='off'
              value={formData.password}
              onChange={({ target }) => updateForm('password', target.value)}
            />
            <Seperator></Seperator>
            <div style={{ marginBottom: '30px' }}>
              <label>
                <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                  What year did you graduate?
                </div>
              </label>
              <Select
                placeholder='Select grad year'
                style={{ width: '100%' }}
                allowClear
                value={formData.grad_year}
                options={Array(55)
                  .fill(null)
                  .map((_, i) => (2023 - i).toString())
                  .map(x => ({ id: x, label: x, value: x }))}
                onChange={v => updateForm('grad_year', v)}
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>
                <div style={{ marginBottom: '20px' }}>
                  What did you major in?
                </div>
              </label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.degree_id?.map(x => x.toString())}
                options={utils.degrees.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'degree_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>
                <div style={{ marginBottom: '20px' }}>
                  Bachelors, Masters, or PhD?
                </div>
              </label>
              <Select
                style={{ width: '100%' }}
                mode='multiple'
                allowClear
                value={formData.grad_level_id?.map(x => x.toString())}
                options={utils.grad_levels.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'grad_level_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='jc_penny'
                checked={formData.jc_penny}
                onChange={e => updateForm('jc_penny', e.target.checked)}
              />
              <label htmlFor='jc_penny'>
                Check this box if you were a part of the JCPenney Leadership
                program?
              </label>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='show_ibc'
                checked={showIbc}
                onChange={e => setShowIbc(e.target.checked)}
              />
              <label htmlFor='show_ibc'>
                Check this box if you were in the IBC program?:
              </label>
            </div>
            {showIbc && (
              <div style={{ marginBottom: '30px' }}>
                <label>Which IBC company?</label>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch={true}
                  value={formData.ibc_company_id?.toString()}
                  options={utils.ibc_companies.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={v => updateForm('ibc_company_id', Number(v))}
                />
              </div>
            )}

            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='show_military_branch'
                checked={showMilBrc}
                onChange={e => setShowMilBrc(e.target.checked)}
              />
              <label htmlFor='show_military_branch'>
                Check if you were or are in the USA Military?:
              </label>
            </div>
            {showMilBrc && (
              <div style={{ marginBottom: '30px' }}>
                <label>Which branch do you most associate with?</label>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.military_branch_id?.toString()}
                  options={utils.military_branches.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v => updateForm('military_branch_id', Number(v))}
                />
              </div>
            )}

            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What student orgs were you in?</label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={formData.student_org_id?.map(x => x.toString())}
                options={utils.student_orgs.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={v =>
                  updateForm(
                    'student_org_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>What associations are you in now?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.association_id?.map(x => x.toString())}
                options={utils.associations.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'association_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Which industries are you a part of?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.industry_id?.map(x => x.toString())}
                options={utils.industries.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'industry_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>What are your hobbies?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.hobby_id?.map(x => x.toString())}
                options={utils.hobbies.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'hobby_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Which cities do you associate with most?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.city_id?.map(x => x.toString())}
                options={utils.cities?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'city_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Select countries you associate with:</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.country_id?.map(x => x.toString())}
                options={utils.countries?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'country_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Which job titles do you have?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.job_title_id?.map(x => x.toString())}
                options={utils.job_titles.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'job_title_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Select high schools or other colleges:</label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={formData.school_id?.map(x => x.toString())}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={utils.schools?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'school_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <Seperator></Seperator>

            <div style={{ marginBottom: 20, marginTop: '20px' }}>
              <label>Write a brief intro about yourself:</label>
              <TextArea
                value={formData.headline ?? ''}
                onChange={e => updateForm('headline', e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label>
                Add a Youtube video to your profile (cut and paste the link
                below):
              </label>
              <Input
                type='url'
                value={formData.youtube_video_url ?? ''}
                onChange={e => updateForm('youtube_video_url', e.target.value)}
              />
            </div>
            <Seperator></Seperator>

            <div
              style={{
                marginBottom: '10px',
                marginTop: '20px',
                color: 'white !important',
              }}
            >
              <div style={{ color: '#fff' }}>
                <Checkbox
                  checked={formData.type === 'OWN'}
                  onChange={e =>
                    updateForm('type', e.target.checked ? 'OWN' : null)
                  }
                >
                  <span style={{ color: 'white' }}>
                    Check if you have a startup company and are looking for
                    cofounders:
                  </span>
                </Checkbox>
              </div>
            </div>
            <div style={{ marginBottom: '20px', color: 'white !important' }}>
              <Checkbox
                checked={formData.type === 'JOIN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'JOIN' : null)
                }
              >
                <span style={{ color: 'white' }}>
                  Check if you are interested in joining a startup as a
                  cofounder:
                </span>
              </Checkbox>
            </div>
            {formData.type !== null && (
              <div style={{ marginBottom: '20px' }}>
                <label style={{ marginBottom: '20px' }}>
                  {formData.type === 'OWN'
                    ? 'Select the roles / skills you are looking to fill: (please include something about your startup in your intro. Boomer Sooner!'
                    : 'Select the roles you might fit into well as a cofounder:'}
                </label>
                <Select
                  mode='multiple'
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.skills_id?.map(x => x.toString())}
                  options={utils.skills?.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v =>
                    updateForm(
                      'skills_id',
                      v.map(x => Number(x)),
                    )
                  }
                />
              </div>
            )}

            <Seperator></Seperator>
            <div
              style={{
                marginBottom: 20,
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_pic}
                  icon={<UserOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_pic', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Upload headshot
                        <div style={{ color: 'yellow' }}>(required!)</div>
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div
              style={{
                marginBottom: 20,
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_url}
                  icon={<AppstoreOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_url', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Upload profile background image
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>

            {loading ? (
              <ThreeCircles />
            ) : (
              <SignFormButton disabled={IsInvalid} type='submit'>
                Join Now - Boomer Sooner!
              </SignFormButton>
            )}
            <SignFormText>
              Already a Member?
              <SignFormLink onClick={handleLoginClick}>
                Sign in here!
              </SignFormLink>
            </SignFormText>
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
};

export default RegisterPage;
