import { useState, useEffect } from 'react';
import HeaderCompound from '../compounds/HeaderCompound';
import FooterCompound from '../compounds/FooterCompound';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import SignFormWrapper from '../components/SignForm/SignFormWrapper';
import SignFormBase from '../components/SignForm/SignFormBase';
import SignFormTitle from '../components/SignForm/SignFormTitle';
import SignFormInput from '../components/SignForm/SignFormInput';
import ImgCrop from 'antd-img-crop';
import { Checkbox, Input, Select, Upload } from 'antd';
import CameraIcon from '../components/icons/CameraIcon';
import { Avatar } from 'antd';
import { UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import SignFormButton from '../components/SignForm/SignFormButton';
import { updateProfile } from '../store/auth/auth.actions';
import TextArea from 'antd/es/input/TextArea';
// import { ThreeCircles } from 'react-loader-spinner';
import Loading from '../assets/images/loading.gif';
interface IFormData {
  first_name: string;
  last_name: string;
  grad_level_id: number[] | null;
  degree_id: number[] | null;
  jc_penny: boolean;
  ibc_company_id: number | null;
  student_org_id: number[] | null;
  association_id: number[] | null;
  industry_id: number[] | null;
  hobby_id: number[] | null;
  city_id: number[] | null;
  country_id: number[] | null;
  job_title_id: number[] | null;
  military_branch_id: number | null;
  school_id: number[] | null;
  profile_pic?: string | null;
  profile_url?: string | null;
  headline: string | null;
  youtube_video_url: string | null;
  grad_year: string | null;
  type: 'OWN' | 'JOIN' | null;
  skills_id: number[] | null;
}

/*---> Component <---*/
const ProfilePage = () => {
  const dispatch = useDispatch<any>();

  const profile = useSelector((state: RootState) => state.auth.profile);
  const utils = useSelector((state: RootState) => state.utils);

  const [formData, setFormData] = useState<IFormData>();
  const [showIbc, setShowIbc] = useState(!!profile?.ibc_company);
  const [showMilBrc, setShowMilBrc] = useState(!!profile?.military_branch);
  const [loading, setLoading] = useState(false);

  const IsInvalid =
    !formData ||
    formData.first_name.length <= 0 ||
    formData.last_name.length <= 0;

  const updateForm = (key: keyof IFormData, value: any) => {
    setFormData(prv => (prv ? { ...prv, [key]: value } : undefined));
  };
  const handleFileChange = (type: 'profile_pic' | 'profile_url', file: any) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      updateForm(type, reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    let form = { ...formData };
    if (form.profile_pic && !form.profile_pic.startsWith('data:image')) {
      delete form.profile_pic;
    }
    if (form.profile_url && !form.profile_url.startsWith('data:image')) {
      delete form.profile_url;
    }
    const res = await dispatch(updateProfile(form));
    setLoading(false);
    if (res !== true) {
      window.alert(res ?? 'Failed to update profile');
    } else {
      window.alert('Profile updated successfully!');
    }
  };

  useEffect(() => {
    if (profile) {
      setFormData(prv => ({
        ...prv,
        ...(profile as any),
        grad_level_id: profile.grad_levels?.map(x => x.id) ?? null,
        degree_id: profile.degrees?.map(x => x.id) ?? null,
        student_org_id: profile.student_orgs?.map(x => x.id) ?? null,
        association_id: profile.associations?.map(x => x.id) ?? null,
        industry_id: profile.industries?.map(x => x.id) ?? null,
        hobby_id: profile.hobbies?.map(x => x.id) ?? null,
        city_id: profile.cities?.map(x => x.id) ?? null,
        country_id: profile.countries?.map(x => x.id) ?? null,
        job_title_id: profile.job_titles?.map(x => x.id) ?? null,
        military_branch_id: profile.military_branch?.id ?? null,
        school_id: profile.schools?.map(x => x.id) ?? null,
        skills_id: profile.skills?.map(x => x.id) ?? null,
      }));
    }
  }, [profile]);

  return (
    <>
      <HeaderCompound hideFeature={true} />
      <SignFormWrapper>
        {formData && (
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>My Profile</SignFormTitle>
            <div style={{ marginBottom: 20 }}>
              <label>Write About Yourself and How to Contact You:</label>
              <TextArea
                value={formData.headline ?? ''}
                onChange={e => updateForm('headline', e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label>Paste In yOUr Youtube video link below:</label>
              <Input
                type='url'
                value={formData.youtube_video_url ?? ''}
                onChange={e => updateForm('youtube_video_url', e.target.value)}
              />
            </div>
            <SignFormInput
              type='text'
              placeholder='First Name'
              value={formData.first_name}
              onChange={({ target }) => updateForm('first_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Last Name'
              value={formData.last_name}
              onChange={({ target }) => updateForm('last_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Email Address'
              disabled={true}
              readOnly={true}
              value={profile?.email}
            />
            <div style={{ marginBottom: '30px' }}>
              <label>Grad Year</label>
              <Select
                placeholder='Select grad year'
                style={{ width: '100%' }}
                allowClear
                value={formData.grad_year}
                options={Array(54)
                  .fill(null)
                  .map((_, i) => (2023 - i).toString())
                  .map(x => ({ id: x, label: x, value: x }))}
                onChange={v => updateForm('grad_year', v)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_pic}
                  icon={<UserOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_pic', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Upload headshot
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_url}
                  icon={<AppstoreOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_url', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Upload masthead
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What degree did you major in:</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.degree_id?.map(x => x.toString())}
                options={utils.degrees.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'degree_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What graduation level did you achieve:</label>
              <Select
                style={{ width: '100%' }}
                mode='multiple'
                allowClear
                value={formData.grad_level_id?.map(x => x.toString())}
                options={utils.grad_levels.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'grad_level_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='jc_penny'
                checked={formData.jc_penny}
                onChange={e => updateForm('jc_penny', e.target.checked)}
              />
              <label htmlFor='jc_penny'>
                Were you a part of the JCPenney Leadership program?
              </label>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='show_ibc'
                checked={showIbc}
                onChange={e => setShowIbc(e.target.checked)}
              />
              <label htmlFor='show_ibc'>Were you in the IBC program?:</label>
            </div>
            {showIbc && (
              <div style={{ marginBottom: '30px' }}>
                <label>Select from IBC companies:</label>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.ibc_company_id?.toString()}
                  options={utils.ibc_companies.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v => updateForm('ibc_company_id', Number(v))}
                />
              </div>
            )}

            <div style={{ marginBottom: '30px' }}>
              <input
                type='checkbox'
                id='show_military_branch'
                checked={showMilBrc}
                onChange={e => setShowMilBrc(e.target.checked)}
              />
              <label htmlFor='show_military_branch'>
                Are you or were you in the USA Military?:
              </label>
            </div>
            {showMilBrc && (
              <div style={{ marginBottom: '30px' }}>
                <label>Select from your Military Branch:</label>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.military_branch_id?.toString()}
                  options={utils.military_branches.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v => updateForm('military_branch_id', Number(v))}
                />
              </div>
            )}
            <div style={{ marginBottom: '30px' }}>
              <label>What student organizations were you a part of?</label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={formData.student_org_id?.map(x => x.toString())}
                options={utils.student_orgs.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={v =>
                  updateForm(
                    'student_org_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>What professional associations do you belong to?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.association_id?.map(x => x.toString())}
                options={utils.associations.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'association_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>
                Add your industries, what industries do you consider yourself a
                part of?
              </label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.industry_id?.map(x => x.toString())}
                options={utils.industries.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'industry_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>
                Got some Hobbies? Select your hobbies & interests please:
              </label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.hobby_id?.map(x => x.toString())}
                options={utils.hobbies.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'hobby_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>
                What cities do you travel to, live in, or associate with most?
              </label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.city_id?.map(x => x.toString())}
                options={utils.cities?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'city_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What countries do you most associate with?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.country_id?.map(x => x.toString())}
                options={utils.countries?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'country_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Which job titles do yOU associate yourself with?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.job_title_id?.map(x => x.toString())}
                options={utils.job_titles.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'job_title_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>
                Which high school or other colleges do you associate yourself
                with?
              </label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={formData.school_id?.map(x => x.toString())}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={utils.schools?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'school_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>

            <div
              style={{
                marginBottom: '10px',
                marginTop: '20px',
                color: 'white !important',
              }}
            >
              <Checkbox
                checked={formData.type === 'OWN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'OWN' : null)
                }
              > <span style={{color: 'white'}}>
                Check if you have a startup company and are looking for cofounders:
                </span>
              </Checkbox>
            </div>
            <div style={{ marginBottom: '20px', color: 'white !important' }}>
              <Checkbox
                checked={formData.type === 'JOIN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'JOIN' : null)
                }
              > <span style={{color: 'white'}}>
                Check if you are interested in joining a startup as a cofounder:
                </span>
              </Checkbox>
            </div>
            {formData.type !== null && (
              <div style={{ marginBottom: '20px' }}>
                <label style={{ marginBottom: '20px' }}>
                  {formData.type === 'OWN'
                    ? 'Select the roles / skills you are looking to fill: (please include something about your startup in your intro. Boomer Sooner!'
                    : 'Select the roles you might fit into well as a cofounder:'}
                </label>
                <Select
                  mode='multiple'
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.skills_id?.map(x => x.toString())}
                  options={utils.skills?.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v =>
                    updateForm(
                      'skills_id',
                      v.map(x => Number(x)),
                    )
                  }
                />
              </div>
            )}

            {loading ? (
              <img src={Loading} alt="" />
            ) : (
              <SignFormButton disabled={IsInvalid} type='submit'>
                Save yOUr Changes
              </SignFormButton>
            )}
          </SignFormBase>
        )}
      </SignFormWrapper>
      <FooterCompound />
    </>
  );
};

export default ProfilePage;
